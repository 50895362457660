<template>
  <div
    v-if="notificationsVisibility"
    class="notification-icon"
    @click.prevent.stop="goToNotifications"
  >
    <VueIcon
      :iconName="iconNotification.name"
      :width="iconNotification.width"
      :height="iconNotification.height"
      iconColor="#23303D"
    />
    <div class="notification-count-card">
      <span class="notification-count">{{ activeNotificationCount }}</span>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance } from 'vue';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RoutesSecure from '@/router/routes/RoutesSecure.js';
import RoutesSettings from '@/router/routes/secure/RoutesSettings';
import { ICON_VARIABLES } from '@/constants/component.constants';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';

export default {
  name: 'NotificationCard',
  components: {
    VueIcon,
  },

  setup() {
    const { proxy } = getCurrentInstance();
    const store = proxy.$store;

    const activeNotificationCount = computed(() => store.getters['app/getNotificationCount']);
    const notificationsVisibility = computed(() => {
      const visibility = store.getters['app/getNotificationsVisibility'];
      return visibility;
    });
    const iconNotification = computed(() => ICON_VARIABLES.iconNotification);

    const goToNotifications = () => {
      const isNotificationPage = proxy.$route.path === '/secure/settings/notifications';
      if (isNotificationPage) return;
      proxy.$router.push(
        `${RoutesRoot.Secure.path}/${RoutesSecure.Settings.path}/${RoutesSettings.Notifications.path}`,
      );
    };

    return {
      activeNotificationCount,
      notificationsVisibility,
      iconNotification,
      goToNotifications,
    };
  },
};
</script>

<style lang="scss" scoped>
.notification-icon {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.notification-count-card {
  border-radius: 18px;
  border: 0.5px solid #ffb800;
  background: #ffb800;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  display: flex;
  left: 15px;
  top: 0;
}

.notification-count {
  color: #fffff0;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
}
</style>
