<template>
  <div class="wrapper" v-if="showModal">
    <div class="input-holder">
      <VueIcon
        :iconName="getIcon.search.name"
        :iconSize="{ width: getIcon.search.width, height: getIcon.search.height }"
        class="search-icon"
        @click="fetchSearchedText"
      ></VueIcon>
      <input
        class="vue-input"
        name="search"
        placeholder="İçerik Ara"
        id="search"
        label="İçerik Ara"
        v-model="search"
        borderType="none"
        @keyup.enter="fetchSearchedText"
      />
    </div>
    <div class="filter-button" @click="click()">
      <VueIcon
        :iconName="getIcon.filter.name"
        :iconSize="{ width: getIcon.filter.width, height: getIcon.filter.height }"
      ></VueIcon>
    </div>
  </div>
</template>

<script>
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import { ICON_VARIABLES } from '@/constants/component.constants';
import { mapGetters } from 'vuex';

export default {
  name: 'BrandSearchBar',
  components: { VueIcon },
  data() {
    return {
      search: '',
    };
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('app', ['getFunPageModalStatus']),
    getIcon() {
      return {
        search: ICON_VARIABLES.search,
        filter: ICON_VARIABLES.filter,
      };
    },
  },
  methods: {
    fetchSearchedText() {
      let searchedText = this.search;
      this.$store.dispatch('app/setSearchedText', searchedText);
    },
    click(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 0 palette-space-level('20');
}

.input-holder {
  .search-icon {
    margin-right: palette-space-level('10');
    width: 20px;
    height: 20px;
  }
  border-radius: palette-radius-level('2');
  padding-left: palette-space-level('15');
  display: flex;
  flex: 0 0 calc(100% - 55px);
  align-items: center;
  width: 100%;
  background-color: palette-color-level('white', '100');
  height: 100%;

  input {
    display: flex;
    flex: 1;
    font-size: palette-font-size-level('4') !important;
    border: none !important;
    & + span {
      display: none;
    }
    &::placeholder {
      letter-spacing: normal;
    }
  }
}

.filter-button {
  border-radius: palette-radius-level('2');
  flex: 0 0 44px;
  background-color: palette-color-level('white', '100');
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    position: relative;
    left: 1px;
  }
}
</style>
