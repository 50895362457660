var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isPublicLayoutBar || _vm.isMenuBar || !_vm.normalizedConfig.disabled)?_c('div',{class:[_vm.cssClasses.appBarHolder, { 'appbar-holder-bg': _vm.withBg }],style:(Object.assign({}, _vm.cssStyle,
    _vm.backgroundStyle,
    {backgroundColor: _vm.isQrView ? '#959FAE' : '#fff'}))},[_c('transition',{attrs:{"appear":"","appear-active-class":"fade-enter-active"}},[_c('div',{class:_vm.cssClasses.appBarHolderInner},[_c('div',{class:_vm.cssClasses.leftContentHolder},[(_vm.backButton)?_c('VueButton',{attrs:{"color":"white-100","iconName":_vm.btnBackIcon.name,"iconSize":{
            width: _vm.btnBackIcon.width,
            height: _vm.btnBackIcon.height,
          },"iconColor":_vm.isQrReaderPage && !_vm.isQrView ? '#eb3832' : _vm.backButtonColor,"ghost":true,"target":"-1"},on:{"click":_vm.onBackButtonClick}}):_c('div',{class:_vm.cssClasses.profileImgHolder,on:{"click":_vm.goToProfilePage}},[_c('img',{attrs:{"src":("" + _vm.userPhoto),"alt":"user"}})])],1),(_vm.isJustLogo || _vm.isQrReaderPage || _vm.isRackPage)?_c('div',{class:_vm.cssClasses.appBarLogoWrapper},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/logos/pmaktif-sm.png"),"alt":"logo"}})])],1):_c('div',{class:_vm.cssClasses.rightContentHolder},[_c('VueHeadline',{attrs:{"weightLevel":"1","level":"2","color":"white-100"}},[(this.getUserModel && !this.getUserModel.isImageRecognitionUser)?_c('span',{staticClass:"head-title"},[_vm._v(" "+_vm._s(_vm.headTitle))]):_vm._e()]),(!_vm.isUserKacc && !_vm.isUserIR && _vm.isPartner)?_c('router-link',{class:_vm.cssClasses.userScoreHolder,attrs:{"exact":"","to":_vm.routes.wallets},nativeOn:{"click":function($event){return _vm.dataLayerEvent.apply(null, arguments)}}},[(_vm.customerPoints === 0 || _vm.customerPoints)?_c('VueText',{class:_vm.cssClasses.userScore,attrs:{"sizeLevel":"5","as":"span","color":"white-100"}},[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.customerPoints)))]):_vm._e(),_c('img',{class:[
              _vm.cssClasses.userScoreIcon,
              _vm.customerPoints > 0 ? _vm.cssClasses.rotatingGlow : null ],attrs:{"src":require("@/assets/images/score.png"),"alt":"score"}}),_c('NotificationCard')],1):_vm._e()],1)])]),(!_vm.isPublicLayoutBar && !_vm.isMenuBar && _vm.normalizedConfig.hasSearchBar)?_c('div',{class:_vm.cssClasses.searchBar},[(!_vm.isMenuBar)?_c('BrandSearchBar',{attrs:{"showModal":_vm.normalizedConfig.hasSearchBar || _vm.hasSearchBar},on:{"click":function($event){return _vm.setFilterModalStatus(!_vm.getFunPageModalStatus)}}}):_vm._e()],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }