<template>
  <div
    v-if="isPublicLayoutBar || isMenuBar || !normalizedConfig.disabled"
    :class="[cssClasses.appBarHolder, { 'appbar-holder-bg': withBg }]"
    :style="{
      ...cssStyle,
      ...backgroundStyle,
      backgroundColor: isQrView ? '#959FAE' : '#fff',
    }"
  >
    <transition appear appear-active-class="fade-enter-active">
      <div :class="cssClasses.appBarHolderInner">
        <div :class="cssClasses.leftContentHolder">
          <VueButton
            v-if="backButton"
            @click="onBackButtonClick"
            color="white-100"
            :iconName="btnBackIcon.name"
            :iconSize="{
              width: btnBackIcon.width,
              height: btnBackIcon.height,
            }"
            :iconColor="isQrReaderPage && !isQrView ? '#eb3832' : backButtonColor"
            :ghost="true"
            target="-1"
          ></VueButton>
          <div v-else :class="cssClasses.profileImgHolder" @click="goToProfilePage">
            <img :src="`${userPhoto}`" alt="user" />
          </div>
        </div>

        <div
          v-if="isJustLogo || isQrReaderPage || isRackPage"
          :class="cssClasses.appBarLogoWrapper"
        >
          <router-link to="/">
            <img src="~@/assets/logos/pmaktif-sm.png" alt="logo" />
          </router-link>
        </div>
        <div v-else :class="cssClasses.rightContentHolder">
          <VueHeadline weightLevel="1" level="2" color="white-100"
            ><span
              class="head-title"
              v-if="this.getUserModel && !this.getUserModel.isImageRecognitionUser"
            >
              {{ headTitle }}</span
            >
          </VueHeadline>
          <router-link
            v-if="!isUserKacc && !isUserIR && isPartner"
            exact
            :to="routes.wallets"
            :class="cssClasses.userScoreHolder"
            @click.native="dataLayerEvent"
          >
            <VueText
              v-if="customerPoints === 0 || customerPoints"
              :class="cssClasses.userScore"
              sizeLevel="5"
              as="span"
              color="white-100"
              >{{ customerPoints | currencyFormat }}</VueText
            >
            <img
              src="@/assets/images/score.png"
              alt="score"
              :class="[
                cssClasses.userScoreIcon,
                customerPoints > 0 ? cssClasses.rotatingGlow : null,
              ]"
            />
            <NotificationCard />
          </router-link>
        </div>
      </div>
    </transition>
    <div
      v-if="!isPublicLayoutBar && !isMenuBar && normalizedConfig.hasSearchBar"
      :class="cssClasses.searchBar"
    >
      <BrandSearchBar
        v-if="!isMenuBar"
        :showModal="normalizedConfig.hasSearchBar || hasSearchBar"
        @click="setFilterModalStatus(!getFunPageModalStatus)"
      ></BrandSearchBar>
    </div>
  </div>
</template>
<script>
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSettings from '@/router/routes/secure/RoutesSettings';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import BrandSearchBar from '@/components/brand/BrandSearch/BrandSearchBar.vue';
import StorageProps from '@/mixins/storageProps.js';
import { mapGetters } from 'vuex';
import currencyFilter from '@/mixins/currencyFilter.js';
import AccountMixin from '@/utils/accountUtils.js';
import gtmUtils from '@/mixins/gtmUtils.js';
import NotificationCard from '@/components/Notifications/NotificationCard';

export default {
  name: 'VueAppbar',
  mixins: [StorageProps, currencyFilter, AccountMixin, gtmUtils],
  props: {
    title: {
      type: String,
      default: '',
    },
    isPublicLayoutBar: {
      type: Boolean,
    },
    isMenuBar: {
      type: Boolean,
    },
    profileImgSrc: {
      type: String,
    },
    hasBackButton: {
      type: Boolean,
      default: false,
    },
    hasSearchBar: {
      type: Boolean,
      default: false,
    },
    backButtonColor: {
      type: String,
      default: '#fff',
    },
    cssStyles: {
      type: Object,
    },
    isJustLogo: {
      type: Boolean,
      default: false,
    },
    withBg: {
      type: Boolean,
    },
    triggerBackButton: {
      type: Boolean,
      default: true,
    },
    backButtonEvent: {
      type: Function,
    },
  },
  data() {
    return {
      scheduledAnimationFrame: false,
    };
  },
  components: {
    VueHeadline,
    VueButton,
    VueText,
    BrandSearchBar,
    NotificationCard,
  },
  computed: {
    ...mapGetters('app', [
      'getAppbarConfig',
      'getFunPageModalStatus',
      'getBackFunction',
      'getHeadTitle',
      'getQrViewStatus',
      'getMainPageBackgroundImage',
    ]),
    ...mapGetters('auth', ['isAuthenticated', 'getUserModel']),
    userPhoto() {
      return this.profileImgSrc && this.profileImgSrc.length
        ? this.profileImgSrc
        : process.env.VUE_APP_USER_PHOTO_PLACEHOLDER;
    },
    isQrView() {
      return this.getQrViewStatus;
    },
    routes() {
      return {
        wallets: `${RoutesRoot.Secure.path}/${RoutesSecure.MyWallets.path}`,
      };
    },
    ...mapGetters('auth', ['getPos']),
    normalizedConfig() {
      return {
        ...this.getAppbarConfig,
      };
    },
    headTitle() {
      if (this.getHeadTitle || this.title) {
        return this.getHeadTitle || this.title;
      } else {
        return this.normalizedConfig.title;
      }
    },

    cssClasses() {
      const appBarHolder = [this.$style['appbar-holder']];
      const appBarHolderInner = [this.$style['appbar-holder-inner']];
      const appBarLogoWrapper = [this.$style['appbar-logo-wrapper']];
      const leftContentHolder = [this.$style['left-content-holder']];
      const profileImgHolder = [this.$style['profile-img-holder']];
      const rightContentHolder = [this.$style['right-content-holder']];
      const userScoreHolder = [this.$style['user-score-holder']];
      const userScoreIcon = [this.$style['user-score-icon']];
      const userScore = [this.$style['user-score']];
      const searchBar = [this.$style['search-bar']];
      const rotatingGlow = ['rotating-glow'];

      return {
        appBarHolder,
        appBarHolderInner,
        appBarLogoWrapper,
        leftContentHolder,
        profileImgHolder,
        rightContentHolder,
        userScoreHolder,
        userScoreIcon,
        userScore,
        searchBar,
        rotatingGlow,
      };
    },
    cssStyle() {
      return this.cssStyles ? { ...this.cssStyles } : null;
    },
    btnBackIcon() {
      return COMPONENT_CONSTANTS.ICON_VARIABLES.arrowLeft;
    },
    backButton() {
      if (this.isMenuBar) {
        return this.hasBackButton;
      } else {
        return this.normalizedConfig.hasBackButton || this.hasBackButton;
      }
    },
    backgroundStyle() {
      if (this.isQrReaderPage || !this.isAuthenticated) {
        return { background: 'none' };
      }

      if (this.isPartner) {
        return {
          background: `url('${this.getMainPageBackgroundImage}')`,
          backgroundSize: 'cover',
        };
      }

      return {
        background: 'top left no-repeat, linear-gradient(to left, #d20051 0%, #ffa300 100%)',
      };
    },
  },
  methods: {
    dataLayerEvent() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('cuzdanlarim', {
        location: 'top-bar',
      });
    },
    setFilterModalStatus(status) {
      this.$store.dispatch('app/setFunPageModalStatus', status);
    },
    goToProfilePage() {
      this.$router.push(
        `${RoutesRoot.Secure.path}/${RoutesSecure.Settings.path}/${RoutesSettings.Profile.path}`,
      );
    },
    onBackButtonClick() {
      if (this.getBackFunction) {
        this.getBackFunction();
      } else {
        if (this.triggerBackButton && this.backButtonEvent) {
          this.$emit('backButtonEvent');
          this.$router.go(-1);
          return;
        }

        if (this.triggerBackButton) {
          this.$router.go(-1);
        } else {
          this.$emit('backButtonEvent');
        }
        return;
      }
    },
  },
};
</script>

<style module lang="scss">
.appbar-holder {
  transform: translate3d(0, 0, 0);
  transition: $animation-transition-duration height $animation-easing;
  background-size: cover;
  min-height: 60px;

  .appbar-logo-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 85px;
    }
  }
  .appbar-holder-inner {
    display: flex;
    align-items: center;
    padding: 0 palette-space-level(20);
    height: 100%;
    height: 60px;
  }

  .left-content-holder {
    margin-right: palette-space-level(5);
  }
  .profile-img-holder {
    width: 40px;
    height: 40px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .right-content-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .user-score-holder {
    height: 26px;
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: flex-end;
    width: 100%;
    max-width: 125px;
    margin-right: 25px;
  }
  .user-score-icon {
    height: 26px;
  }
  h2 {
    max-width: 175px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .user-score {
    padding: 3px palette-space-level(10);
    border: 1px solid palette-color-level('white', '100');
    border-radius: palette-space-level(15);
    margin-right: 10px;
  }
  .search-bar {
    padding-bottom: palette-space-level(18);
  }
}
</style>
<style lang="scss" scoped>
@import '@/styles/_animations.scss';
.head-title {
  font-size: palette-font-size-level(5);
}
</style>
